<template>
   <div class="rb_hot_click">
                <h3 class="rb_hot_click_title">博主信息</h3>

                <ul style="cursor: pointer; margin-top: 10px">
                  <li>
                    <h3 class="Userinfo">职业：程序员，后台工程师，phper</h3>
                  </li>
                  <li>
                    <h3 class="Userinfo">现居：广东省-深圳市</h3>
                  </li>

                  <li>
                    <h3 class="Userinfo">Email：1377072793@qq.com</h3>
                  </li>
                  <li>
                    <h3 class="Userinfo">电话：18174003508</h3>
                  </li>
                </ul>
              </div>
</template>


<script>
export default {};
</script>

<script>
export default {

};
</script>

<style scoped lang="less">
.rb_hot_click {
  position: relative;
  padding: 15px;
  overflow: hidden;
  margin-bottom: 15px;
  background: #fff;
  border: 1px solid #eee;
  vertical-align: bottom;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 8px;

  .rb_hot_click_title {
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    position: relative;
    font-size: 16px;
    z-index: 9;
  }
  .rb_hot_click_title:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    width: 3px;
    height: 22px;
    background-color: #00a4ff;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 50px;
  }
  ul,
  li,
  dl,
  dd,
  dt {
    list-style-type: none;
  }

  ul li {
    overflow: hidden;
    padding: 0px 0px;
    line-height: 40px;
    height: 40px;
    border-bottom: 1px #ddd solid;
     color: black;
    position: relative;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
    margin-top: 1px;
  }

  ul li a {
    display: block;
    width: 86%;
    margin: 10px auto 0;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    margin-left: 0px;
  }

  html,
  body,
  div,
  p,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  form,
  input,
  select,
  button,
  textarea,
  iframe,
  table,
  th,
  td {
    margin: 0;
    padding: 0;
  }

  li {
    text-align: -webkit-match-parent;
  }

  ul li a .title {
    color: #333;
    font-size: 13px;
    // margin-block-start: 1.33em;
    // margin-block-end: 1.33em;
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
  }

  ul li .Userinfo {
    font-size: 13px;

    // margin-block-start: 1.33em;
    // margin-block-end: 1.33em;
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
  }

  #divComments {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    font-family: "Microsoft Yahei", Helvetica, Arial, sans-serif;
    line-height: 1.42857143;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    background: transparent;
    vertical-align: baseline;
    font-size: 100%;
    transition: ease-in-out 0.3s;
    margin-top: -8px;

    .divComments1 {
      position: relative;
      width: 100%;
      cursor: pointer;
      overflow: hidden;
      padding: 10px 00px 65px 60px;
      border: 0;
      border-bottom: 1px solid #ddd;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background-image: none;
    }

    .zb_avatarp {
      line-height: 30px;
      height: 30px;
      margin-left: 10px;
      font-size: 14px;
      display: block;
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      -webkit-transition: 0.4s;
      -webkit-transition: -webkit-transform.4s ease-out;
      transition: transform.4s ease-out;
      -moz-transition: -moz-transform.4s ease-out;
      border: 1px solid #dfdfdf;
      -webkit-box-shadow: 0 0 10px #fff;
      box-shadow: 0 0 10px #fff;

      transition: all 0.6s;
    }
    img:hover {
      transform: scale(1.4);
    }

    .zb_avatar {
      position: absolute;
      left: 10px;
      top: 13px;
      width: 70px;
      height: 70px;
    }

    small {
      line-height: 20px;
      height: 20px;
      overflow: hidden;
      font-size: 11px;
      color: #999;
      display: block;
      border: 0;
      margin: 0;
      margin-left: 10px;
      padding: 0;

      .avatar_lf {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .fa {
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        i.fa {
          padding-right: 3px;
        }

        .fa-user:before {
          content: "\f007";
        }
      }

      .right,
      .fr {
        float: right;
      }
    }
  }

  ul li a .serialNumber {
    display: block;
    width: 16px;
    height: 16px;
    float: left;
    background: #b1b1b1;
    position: relative;
    top: 7px;
    margin-left: 6px;
    margin-right: 4px;
    font-size: 9px;
    text-align: center;
    line-height: 16px;
    color: #fff;
    border-radius: 50px;
  }

  .serialNumber_cur {
    border-radius: 50px;
    background: #ff6600 !important;
  }
  .serialNumber_curred {
    border-radius: 50px;
    background: red !important;
  }
  .serialNumber_curthree {
    border-radius: 50px;
    background: blue !important;
  }
}
</style>